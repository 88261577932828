import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import parse from "html-react-parser"

function ThankYouPage() {
  const { allPrismicThankyou } = useStaticQuery(graphql`
    query ThankYouQuery {
      allPrismicThankyou {
        edges {
          node {
            data {
              title {
                html
              }
              body {
                html
              }
            }
          }
        }
      }
    }
  `)

  const { title, body } = allPrismicThankyou.edges[0].node.data

  return (
    <Layout>
      <SEO
        keywords={[`end`, `life`, `funeral`, `planning`, `lantern`]}
        title="Thank You | Lantern"
      />
      <div className="bg-grey-lighter">
        <div className="max-w-body mx-auto pt-8 px-4 pb-8 xl:px-0">
          <div className="p-4 md:w-3/4 md:mx-auto text-center">
            <div className="text-3xl md:pt-12 py-4 md:pb-4">
              <strong>{parse(title.html)}</strong>
            </div>
            <div className="page-layout">{parse(body.html)}</div>
          </div>
          <div className="bg-grey-lighter w-full pb-10 mx-auto text-center">
            <Link className="text-primary font-bold hover:text-grey" to="/">
              ← Back to our site
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYouPage
